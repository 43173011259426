import { Form, InputGroup } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import db from "../../firebase";
import { doc, setDoc, collection, getDocs, query, where, updateDoc } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';

const Prices = () => {
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState(false);
    
    // Toastify
    const toastId = useRef(null);
    const showToastMessage = () => toastId.current = toast.success("Hello", {
         autoClose: true,
         position: "bottom-center",
         hideProgressBar: true,
         theme: 'colored'
        });

    // Get Prices
    const getPrices = async () => {
        setLoading(true);
        const pricesCollectionRef = collection(db, "prices");
        await getDocs(pricesCollectionRef)
        .then((querySnapshot)=>{
            const pricesData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setPrices(pricesData);
            setLoading(false);
        })
    }

    // Update Sale Prices
    const updateSalePriceHandler = async(event) => {
        event.preventDefault();
        setLoading(true);
        await updateDoc(doc(db, "prices", "salePrices"), {
            auctionSalePur: event.target.auctionSalePur.value,
            bridgingFinance: event.target.bridgingFinance.value,
            companiesHouseCharge: event.target.companiesHouseCharge.value,
            idCheck: event.target.idCheck.value,
            companiesHouse: event.target.companiesHouse.value,
            isaScheme: event.target.isaScheme.value,
            landSearch: event.target.landSearch.value,
            landSearchCompany: event.target.landSearchCompany.value,
            lawyerChecker: event.target.lawyerChecker.value,
            leaseholdFlat: event.target.leaseholdFlat.value,
            leaseholdFlatRemortgage: event.target.leaseholdFlatRemortgage.value,
            leaseholdHouse: event.target.leaseholdHouse.value,
            // legalFee: event.target.legalFee.value,
            newBuild: event.target.newBuild.value,
            // searches: event.target.searches.value,
            sharedOwner: event.target.sharedOwner.value,
            stampDutyLandTax: event.target.stampDutyLandTax.value,
            ttFee: event.target.ttFee.value,
            unregisteredProperty: event.target.unregisteredProperty.value,
            legalFee1: event.target.legalFee1.value,
            legalFee2: event.target.legalFee2.value,
            legalFee3: event.target.legalFee3.value,
            legalFee4: event.target.legalFee4.value,
            legalFee5: event.target.legalFee5.value,
            legalFee6: event.target.legalFee6.value,
            legalFeeR1: event.target.legalFeeR1.value,
            legalFeeR2: event.target.legalFeeR2.value,
            legalFeeR3: event.target.legalFeeR3.value,
            legalFeeR4: event.target.legalFeeR4.value,
            legalFeeR5: event.target.legalFeeR5.value,
            legalFeeR6: event.target.legalFeeR6.value,
            legalFeeR7: event.target.legalFeeR7.value,
        })
        .then(
            setLoading(false),
            // Toastify
            showToastMessage(),
            toast.update(toastId.current, {
                render: "Sale Prices Updated Successfully!",
            })
            // Toastify
        )
    }

    // Update Purchase Prices
    const updatePurchasePriceHandler = async(event) => {
        event.preventDefault();
        setLoading(true);
        await updateDoc(doc(db, "prices", "purchasePrices"), {            
            auctionSalePur: event.target.auctionSalePur.value,
            bridgingFinance: event.target.bridgingFinance.value,
            companiesHouseCharge: event.target.companiesHouseCharge.value,
            idCheck: event.target.idCheck.value,
            companiesHouse: event.target.companiesHouse.value,
            isaScheme: event.target.isaScheme.value,
            landSearch: event.target.landSearch.value,
            landSearchCompany: event.target.landSearchCompany.value,
            lawyerChecker: event.target.lawyerChecker.value,
            leaseholdFlat: event.target.leaseholdFlat.value,
            leaseholdHouse: event.target.leaseholdHouse.value,
            // legalFee: event.target.legalFee.value,
            newBuild: event.target.newBuild.value,
            searches: event.target.searches.value,
            sharedOwner: event.target.sharedOwner.value,
            stampDutyLandTax: event.target.stampDutyLandTax.value,
            ttFee: event.target.ttFee.value,
            unregisteredProperty: event.target.unregisteredProperty.value,
            legalFee1: event.target.legalFee1.value,
            legalFee2: event.target.legalFee2.value,
            legalFee3: event.target.legalFee3.value,
            legalFee4: event.target.legalFee4.value,
            legalFee5: event.target.legalFee5.value,
            legalFee6: event.target.legalFee6.value,
            landRegFee1: event.target.landRegFee1.value,
            landRegFee1NB: event.target.landRegFee1NB.value,
            landRegFee2: event.target.landRegFee2.value,
            landRegFee2NB: event.target.landRegFee2NB.value,
            landRegFee3: event.target.landRegFee3.value,
            landRegFee3NB: event.target.landRegFee3NB.value,
            landRegFee4: event.target.landRegFee4.value,
            landRegFee4NB: event.target.landRegFee4NB.value,
            landRegFee5: event.target.landRegFee5.value,
            landRegFee5NB: event.target.landRegFee5NB.value,
            landRegFee6: event.target.landRegFee6.value,
            landRegFee6NB: event.target.landRegFee6NB.value,
        })
        .then(
            setLoading(false),
            // Toastify
            showToastMessage(),
            toast.update(toastId.current, {
                render: "Prices Updated Successfully!",
            })
            // Toastify
        )
    }
    
    useEffect(() => {
        getPrices();
    }, []);

    return (
        <div class="lg_s content">
            <div class="container">
                <ToastContainer />
                <Tabs defaultActiveKey="sale" className="mb-3">
                    <Tab eventKey="sale" title="Sale/Remortgage" className="tabContent">
                        {prices.length > 0 ? (
                            <form onSubmit={updateSalePriceHandler}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price {'<'} 150001)</InputGroup.Text>
                                    <Form.Control name="legalFee1" defaultValue={prices[1].legalFee1} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 150000 & 250000)</InputGroup.Text>
                                    <Form.Control name="legalFee2" defaultValue={prices[1].legalFee2} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 250000 & 500000)</InputGroup.Text>
                                    <Form.Control name="legalFee3" defaultValue={prices[1].legalFee3} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 500000 & 750000)</InputGroup.Text>
                                    <Form.Control name="legalFee4" defaultValue={prices[1].legalFee4} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 750000 & 1000000)</InputGroup.Text>
                                    <Form.Control name="legalFee5" defaultValue={prices[1].legalFee5} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 1000000 & 2000000)</InputGroup.Text>
                                    <Form.Control name="legalFee6" defaultValue={prices[1].legalFee6} />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price {'<'} 150001)</InputGroup.Text>
                                    <Form.Control name="legalFeeR1" defaultValue={prices[1].legalFeeR1} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price Between 150000 & 250000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR2" defaultValue={prices[1].legalFeeR2} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price Between 250000 & 500000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR3" defaultValue={prices[1].legalFeeR3} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price Between 500000 & 750000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR4" defaultValue={prices[1].legalFeeR4} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price Between 750000 & 1000000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR5" defaultValue={prices[1].legalFeeR5} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price Between 1000000 & 2000000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR6" defaultValue={prices[1].legalFeeR6} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee Remortgage (Price {'>'} 2000000)</InputGroup.Text>
                                    <Form.Control name="legalFeeR7" defaultValue={prices[1].legalFeeR7} />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">ID Check</InputGroup.Text>
                                    <Form.Control name="idCheck" defaultValue={prices[1].idCheck} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Lawyer Checker</InputGroup.Text>
                                    <Form.Control name="lawyerChecker" defaultValue={prices[1].lawyerChecker} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">TT Fee</InputGroup.Text>
                                    <Form.Control name="ttFee" defaultValue={prices[1].ttFee} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Stamp Duty Land Tax</InputGroup.Text>
                                    <Form.Control name="stampDutyLandTax" defaultValue={prices[1].stampDutyLandTax} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Leasehold Flat</InputGroup.Text>
                                    <Form.Control name="leaseholdFlat" defaultValue={prices[1].leaseholdFlat} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Leasehold Flat Remortgage</InputGroup.Text>
                                    <Form.Control name="leaseholdFlatRemortgage" defaultValue={prices[1].leaseholdFlatRemortgage} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Leasehold House</InputGroup.Text>
                                    <Form.Control name="leaseholdHouse" defaultValue={prices[1].leaseholdHouse} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">New Build Fee</InputGroup.Text>
                                    <Form.Control name="newBuild" defaultValue={prices[1].newBuild} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Shared Owner</InputGroup.Text>
                                    <Form.Control name="sharedOwner" defaultValue={prices[1].sharedOwner} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">ISA Scheme</InputGroup.Text>
                                    <Form.Control name="isaScheme" defaultValue={prices[1].isaScheme} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Unregistered Property</InputGroup.Text>
                                    <Form.Control name="unregisteredProperty" defaultValue={prices[1].unregisteredProperty} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Auction Sale Purchase Fee</InputGroup.Text>
                                    <Form.Control name="auctionSalePur" defaultValue={prices[1].auctionSalePur} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Bridging Finance</InputGroup.Text>
                                    <Form.Control name="bridgingFinance" defaultValue={prices[1].bridgingFinance} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Companies House</InputGroup.Text>
                                    <Form.Control name="companiesHouse" defaultValue={prices[1].companiesHouse} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Companies House Charge</InputGroup.Text>
                                    <Form.Control name="companiesHouseCharge" defaultValue={prices[1].companiesHouseCharge} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Search Company</InputGroup.Text>
                                    <Form.Control name="landSearchCompany" defaultValue={prices[1].landSearchCompany} />
                                </InputGroup>
                                {/* <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Searches Fee</InputGroup.Text>
                                    <Form.Control name="searches" defaultValue={prices[1].searches} />
                                </InputGroup> */}
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Search</InputGroup.Text>
                                    <Form.Control name="landSearch" defaultValue={prices[1].landSearch} />
                                </InputGroup>
                                {/* <Button variant="primary">Update Prices</Button> */}
                                <Button variant="primary" type="submit">Save</Button>
                            </form>
                        ) : null}
                    </Tab>
                    <Tab eventKey="purchase" title="Purchase">
                        {prices.length > 0 ? (
                            <form onSubmit={updatePurchasePriceHandler}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price {'<'} 150001)</InputGroup.Text>
                                    <Form.Control name="legalFee1" defaultValue={prices[0].legalFee1} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 150000 & 250000)</InputGroup.Text>
                                    <Form.Control name="legalFee2" defaultValue={prices[0].legalFee2} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 250000 & 500000)</InputGroup.Text>
                                    <Form.Control name="legalFee3" defaultValue={prices[0].legalFee3} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 500000 & 750000)</InputGroup.Text>
                                    <Form.Control name="legalFee4" defaultValue={prices[0].legalFee4} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 750000 & 1000000)</InputGroup.Text>
                                    <Form.Control name="legalFee5" defaultValue={prices[0].legalFee5} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Legal Fee (Price Between 1000000 & 2000000)</InputGroup.Text>
                                    <Form.Control name="legalFee6" defaultValue={prices[0].legalFee6} />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free {'<'} 8000</InputGroup.Text>
                                    <Form.Control name="landRegFee1" defaultValue={prices[0].landRegFee1} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) {'<'} 8000</InputGroup.Text>
                                    <Form.Control name="landRegFee1NB" defaultValue={prices[0].landRegFee1NB} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free Between 8000 & 100000</InputGroup.Text>
                                    <Form.Control name="landRegFee2" defaultValue={prices[0].landRegFee2} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) Between 8000 & 100000</InputGroup.Text>
                                    <Form.Control name="landRegFee2NB" defaultValue={prices[0].landRegFee2NB} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free Between 100000 & 200000</InputGroup.Text>
                                    <Form.Control name="landRegFee3" defaultValue={prices[0].landRegFee3} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) Between 100000 & 200000</InputGroup.Text>
                                    <Form.Control name="landRegFee3NB" defaultValue={prices[0].landRegFee3NB} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free Between 200000 & 500000</InputGroup.Text>
                                    <Form.Control name="landRegFee4" defaultValue={prices[0].landRegFee4} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) Between 200000 & 500000</InputGroup.Text>
                                    <Form.Control name="landRegFee4NB" defaultValue={prices[0].landRegFee4NB} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free Between 500000 & 1000000</InputGroup.Text>
                                    <Form.Control name="landRegFee5" defaultValue={prices[0].landRegFee5} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) Between 500000 & 1000000</InputGroup.Text>
                                    <Form.Control name="landRegFee5NB" defaultValue={prices[0].landRegFee5NB} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free {'>'} 1000000</InputGroup.Text>
                                    <Form.Control name="landRegFee6" defaultValue={prices[0].landRegFee6} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Registration Free(New Build) {'>'} 1000000</InputGroup.Text>
                                    <Form.Control name="landRegFee6NB" defaultValue={prices[0].landRegFee6NB} />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">ID Check</InputGroup.Text>
                                    <Form.Control name="idCheck" defaultValue={prices[0].idCheck} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Lawyer Checker</InputGroup.Text>
                                    <Form.Control name="lawyerChecker" defaultValue={prices[0].lawyerChecker} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">TT Fee</InputGroup.Text>
                                    <Form.Control name="ttFee" defaultValue={prices[0].ttFee} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Stamp Duty Land Tax</InputGroup.Text>
                                    <Form.Control name="stampDutyLandTax" defaultValue={prices[0].stampDutyLandTax} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Leasehold Flat</InputGroup.Text>
                                    <Form.Control name="leaseholdFlat" defaultValue={prices[0].leaseholdFlat} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Leasehold House</InputGroup.Text>
                                    <Form.Control name="leaseholdHouse" defaultValue={prices[0].leaseholdHouse} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">New Build Fee</InputGroup.Text>
                                    <Form.Control name="newBuild" defaultValue={prices[0].newBuild} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Shared Owner</InputGroup.Text>
                                    <Form.Control name="sharedOwner" defaultValue={prices[0].sharedOwner} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">ISA Scheme</InputGroup.Text>
                                    <Form.Control name="isaScheme" defaultValue={prices[0].isaScheme} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Unregistered Property</InputGroup.Text>
                                    <Form.Control name="unregisteredProperty" defaultValue={prices[0].unregisteredProperty} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Auction Sale Purchase Fee</InputGroup.Text>
                                    <Form.Control name="auctionSalePur" defaultValue={prices[0].auctionSalePur} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Bridging Finance</InputGroup.Text>
                                    <Form.Control name="bridgingFinance" defaultValue={prices[0].bridgingFinance} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Companies House</InputGroup.Text>
                                    <Form.Control name="companiesHouse" defaultValue={prices[0].companiesHouse} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Companies House Charge</InputGroup.Text>
                                    <Form.Control name="companiesHouseCharge" defaultValue={prices[0].companiesHouseCharge} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Search Company</InputGroup.Text>
                                    <Form.Control name="landSearchCompany" defaultValue={prices[0].landSearchCompany} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Searches Fee</InputGroup.Text>
                                    <Form.Control name="searches" defaultValue={prices[0].searches} />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">Land Search</InputGroup.Text>
                                    <Form.Control name="landSearch" defaultValue={prices[0].landSearch} />
                                </InputGroup>
                                <Button variant="primary" type="submit">Save</Button>
                            </form>
                        ) : null}
                    </Tab>
                </Tabs>
            </div>
            {loading === true ? (
                <div className="loading">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ): null}
        </div>
    )
}

export default Prices;