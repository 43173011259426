import { useState, useEffect, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { auth } from '../../firebase';
import db from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PencilSquare } from "react-bootstrap-icons";
import { createSearchParams } from "react-router-dom";

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [newUserModalShow, setNewUserModalShow] = useState(false);
    const [editUserModalShow, setEditUserModalShow] = useState(false);
    const [newName, setNewName] = useState(false);
    const [newEmail, setNewEmail] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [fireUsers, setFireUsers] = useState([]);

    const [editUserId, setEditUserId] = useState();
    const [editUserName, setEditUserName] = useState();
    const [editUserEmail, setEditUserEmail] = useState();

    const newUserCloseModalHandler = () => setNewUserModalShow(false);
    const newUserShowModalHandler = () => setNewUserModalShow(true); 

    const editUserShowModalHandler = (id, name, email) => {
        setEditUserName(name);
        setEditUserEmail(email);
        setEditUserId(id);
        setEditUserModalShow(true);
    }

    const editUserCloseModalHandler = () => setEditUserModalShow(false);
    // Toastify
    const toastId = useRef(null);
    const showToastMessage = () => toastId.current = toast.success("Hello", {
         autoClose: true,
         position: "bottom-center",
         hideProgressBar: true,
         theme: 'colored'
        });
    // Toastify
    
    const newUserHandler = async() => {
        setLoading(true);
        const {user} = await createUserWithEmailAndPassword(
            auth,
            newEmail,
            newPassword
        )
        .catch(error => {
            alert(error.message)
            setLoading(false)
        })
        await setDoc(doc(db, "users", user.uid), {
            name: newName,
            email: newEmail,
            role: 'user',
            timeStamp: Date().toLocaleString()
        })
        .then(
            setLoading(false),
            setNewUserModalShow(false),
            getUsers(),
            // Toastify
            showToastMessage(),
            toast.update(toastId.current, {
                render: "New User Added!",
            })
            // Toastify
        )
    }

    function delay(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    const editUserHandler = async() => {
        setLoading(true);
        await updateDoc(doc(db, "users", editUserId), {
            name: editUserName,
            email: editUserEmail,
        })
        .then(
            setEditUserModalShow(false),
            await delay(100),
            setLoading(false),
            getUsers(),
            // Toastify
            showToastMessage(),
            toast.update(toastId.current, {
                render: "User Details Updated Successfully!",
            })
            // Toastify
        )
    }

    //Delete User
    const deleteUser = async(userId) => {
        alert(userId)
        // await deleteDoc(doc(db, "users", user.uid));
    }

    const getUsers = async () => {
        console.log('getusers');
        setLoading(true);
        const usersCollectionRef = collection(db, "users");
        await getDocs(collection(db, "users"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setFireUsers(newData);
            setLoading(false);
        })
    }
    //Get All Users    
    useEffect(() => {        
        getUsers();
    }, []);

    return (
        <>
            <div className="lg_s content">
                <div className="container">
                    <ToastContainer />
                    <div class="user row">
                        <div class="col d-flex justify-content-start">
                            <h1>Users</h1>
                        </div>
                        <div class="col d-flex justify-content-end">
                            <Button variant="primary" onClick={newUserShowModalHandler}>
                                Add New User
                            </Button>
                        </div>
                    </div>
                    <div class="row">
                        <ul className="usersList">
                            <li>
                                <div className="userHeading">Name</div>
                                <div className="userHeading">Email</div>
                                <div className="userHeading">Role</div>
                                <div className="userHeading">Edit</div>
                            </li>
                            {fireUsers?.map((user,i)=>(
                                <li>
                                    <div className="userName">{user.name}</div>
                                    <div className="userEmail">{user.email}</div>
                                    <div className="userEmail">{user.role}</div>
                                    <div className="deleteEmail">
                                        <PencilSquare onClick={() => editUserShowModalHandler(user.id, user.name, user.email)}/>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {loading === true ? (
                    <div className="loading">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ): null}
            </div>

            {/* New user modal */}
            <Modal show={newUserModalShow} onHide={newUserCloseModalHandler} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" autoFocus onChange={e => setNewName(e.target.value) }/>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" placeholder="name@example.com" onChange={e => setNewEmail(e.target.value) }/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password" onChange={e => setNewPassword(e.target.value) }/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={newUserCloseModalHandler}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={newUserHandler}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit user modal */}
            <Modal show={editUserModalShow} onHide={editUserCloseModalHandler} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Control type="hidden" name="editUserId" value={editUserId} />
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" value={editUserName} autoFocus onChange={e => setEditUserName(e.target.value) }/>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" value={editUserEmail} placeholder="name@example.com" onChange={e => setEditUserEmail(e.target.value) }/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={editUserCloseModalHandler}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={editUserHandler}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Users;