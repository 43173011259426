import { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom'
import { EyeSlash } from 'react-bootstrap-icons';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
       
    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            navigate("/dashboard")
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorCode, errorMessage)
        });
       
    }

    useEffect(() => {
        const togglePassword = document.querySelector('#togglePassword');
        const pswrd = document.querySelector('#password');
        togglePassword.addEventListener('click', () => {
            const type = pswrd
                .getAttribute('type') === 'password' ?
                'text' : 'password';
                pswrd.setAttribute('type', type);
            togglePassword.classList.toggle('bi-eye');
        });
    }, []);
 
    return(
        <div className="login_page">
            <div className="login_form_page">
                <div>
                    <img src="./images/logo.webp" alt="logo.png" width="250px" />
                </div>
                <form method="post" className="login_form mt-4">
                    <h1 className="title">Sign in to Admin</h1>
                    <div className="email-group mt-3">
                        <label className="label" for="email">Email Address</label>
                        <input type="email" name="email" id="email" className="form-control"  placeholder="Email address" onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div className="password-group mt-3">
                        <label className="label" for="password">Password</label>
                        <div className="d-flex align-items-center">
                            <input type="password" name="password" id="password" className="form-control"placeholder="Password" onChange={(e)=>setPassword(e.target.value)} />
                            <EyeSlash id="togglePassword" className='bi bi-eye-slash hide_pass'/>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-between align-items-center">
                        {/* <div className="d-flex">
                            <input type="checkbox" className="form-check-input" id="logged" />
                            <label className="form-check-label mx-1 forget" for="logged">Keep me logged in</label>
                        </div> */}
                        <div className="forget">
                            <a href="forget.html">Forgot password<i className="bi bi-question-lg" /></a>
                        </div>
                    </div>
                    <div className="d-flex mt-4 justify-content-center align-items-center">
                        <button type="submit" className="btn-1" onClick={onLogin}>Sign In</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
 
export default Login