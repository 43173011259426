import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase";
import { Navigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Case = (props)=> {
    const params = useParams();
    const [quote, setQuote] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getQuote = async () => {
            const docRef = doc(db, 'quotes', params.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setQuote(docSnap.data())
                setLoading(false)
              } else {
                console.log("No such document!");
              }
        }
        getQuote();
    }, []);
    const navigate = useNavigate();
    return (
        <>
            <div class="lg_s content">
                {loading === true ? (
                    <div className="loading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ): null}
                <div class="container">
                    <div className="back_button">
                        <button onClick={() => navigate(-1)}>Back</button>
                    </div>
                    <div className="row">
                        {/* ID: {params.id} */}
                        <div className="col justify-content-start">
                            {quote.length !== 0 ? (
                                <>
                                    <table className="half_width_table">
                                        <tr>
                                            <td><strong>Quote Type</strong></td>
                                            <td>{quote.quoteType}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Client Name</strong></td>
                                            <td>{quote.aboutDetails.forename} {quote.aboutDetails.forename}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Client Email</strong></td>
                                            <td>{quote.aboutDetails.enteredEmail}</td>
                                        </tr>
                                        {quote.source ? (
                                            <tr>
                                                <td><strong>Source</strong></td>
                                                <td>{quote.source}</td>
                                            </tr>
                                        ) :null}
                                    </table>
                                </>
                            ):null}
                        </div>
                        <div className="col">
                            <div className="input-group mb-3">
                                <span className="input-group-text">Status: </span>
                                {quote.quoteType === 'Sale' ? (
                                    <select className="form-control">
                                        <option>Quoted</option>
                                        <option>Instructed</option>
                                        <option>Accepted</option>
                                        <option>Starter pack sent</option>
                                        <option>Starter pack received</option>
                                        <option>EA sale memo received</option>
                                        <option>Draft Contract Issued</option>
                                        <option>Enquiries received</option>
                                        <option>Responses sent</option>
                                        <option>Exchanged</option>
                                        <option>Completion</option>
                                    </select>
                                ) : quote.quoteType === "Remortgage" ? (
                                    <select className="form-control">
                                        <option>Quoted</option>
                                        <option>Instructed</option>
                                        <option>Accepted</option>
                                        <option>Starter pack sent</option>
                                        <option>Starter pack received</option>
                                        <option>Search/Indemnity ordered</option>
                                        <option>Search/Indemnity received</option>
                                        <option>Enquiries raised</option>
                                        <option>Responses received</option>
                                        <option>Exchanged</option>
                                        <option>Completion</option>
                                    </select>
                                ) : quote.quoteType === "Purchase" ? (
                                    <select className="form-control">
                                        <option>Quoted</option>
                                        <option>Instructed</option>
                                        <option>Accepted</option>
                                        <option>Starter pack sent</option>
                                        <option>Starter pack received</option>
                                        <option>EA sale memo received</option>
                                        <option>Search Ordered</option>
                                        <option>Search received</option>
                                        <option>Draft Contract received</option>
                                        <option>Enquiries raised</option>
                                        <option>Responses received</option>
                                        <option>Exchanged</option>
                                        <option>Completion</option>
                                    </select>
                                ) :null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Case;