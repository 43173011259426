import {useMemo, useEffect, useState, Fragment } from "react";
import { collection, getDocs, orderBy } from "firebase/firestore";
import db from "../../firebase";
import { useSortBy, useTable, useGlobalFilter, useAsyncDebounce, useFilters, usePagination } from "react-table";
import { CaretDown, CaretDownFill, CaretUp, CaretUpFill } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState([]);
    const updateGlobalFilter = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 300);
    return (
        <input className="form-control search_field" value={value} onChange={e => {
            setValue(e.target.value);
            updateGlobalFilter(e.target.value);
        }} placeholder="Search Quotes" />
    )
}

function Table({ columns, data }) {

    //Edit Button
    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                id: "Edit",
                Header: "Edit",
                Cell: ({row}) => (
                    <NavLink to={"/case/"+row.values.id} >Edit</NavLink>
                )
            }
        ])
    };

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        preGlobalFilteredRows,
        setFilter,
        setGlobalFilter,
        state,
        gotoPage,
        nextPage,
        previousPage,
        pageCount,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    },
        useFilters,
        useGlobalFilter,
        tableHooks,
        useSortBy,
        usePagination
    );

    // Render the UI for your table
    return (
        <>
            <div className="row">
                <div className="col d-flex justify-content-start">
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
                <div className="col d-flex align-content-center justify-content-end">
                    Show
                    <select className="form-control show_per_page ms-2 me-2" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                        {[1, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    Per page
                </div>
            </div>
            <table className="table table-bordered table-hover mt-4 mb-4" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <Fragment>
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <div className="sort_arrows"><CaretUp /><CaretDownFill /></div>
                                : <div className="sort_arrows"><CaretUpFill /><CaretDown /></div>
                            : <div className="sort_arrows"><CaretUp /><CaretDown /></div>}
                        </span>
                        </th>
                        </Fragment>
                    ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(
                    (row, i) => {
                    prepareRow(row);
                    return (
                        <Fragment>
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                )
                            })}
                            </tr>
                        </Fragment>
                    )}
                )}
                </tbody>
            </table>
            <div className="d-flex justify-content-between pagination">
                <div className="">
                    <button className="btn btn-light me-3" onClick={() => gotoPage(0)}>First page</button>
                    <button className="btn btn-light" onClick={previousPage}>Prev</button>
                </div>
                <div className="d-flex align-items-center">
                    <div className="pe-4">Page {pageIndex + 1} of {pageOptions.length}</div>
                    <div className="go_page">
                        Go to page:{' '}
                        <input
                            type="number"
                            className="form-control no-block"
                            value={pageIndex + 1}
                            max={pageOptions.length}
                            min='1'
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </div>{' '}
                </div>
                <div className="">
                    <button className="btn btn-light me-3" onClick={nextPage}>Next</button>
                    <button className="btn btn-light" onClick={() => gotoPage(pageCount - 1)}>Last page</button>
                </div>
            </div>
        </>
    )
}
const CasesQuoted = () => {
    const [loading, setLoading] = useState(true);
    const [fireQuotes, setFireQuotes] = useState([]);
    const quotesCollectionRef = collection(db, "quotes");
    useEffect(() => {
        const getQuotes = async () => {
            const data = await getDocs(quotesCollectionRef, orderBy('createdAt'));            
            const temp_data = [];
            data.forEach(quote => {
                
                const createdDate = new Date(quote.data().createdAt.seconds * 1000).toLocaleDateString("en-US")
                const createdTime = quote.data().createdAt.toDate().toLocaleTimeString("en-US");

                temp_data.push({
                    id: quote.id,
                    createdDate: createdDate+' At '+createdTime,
                    ...quote.data()
                })
            })
            setFireQuotes(temp_data);
            setLoading(false);
        }
        getQuotes()
    }, []);
    //Tables
    const columns = useMemo(
        () => [
          {
            Header: 'Cases Quoted',
            columns: [
              {
                Header: 'Ref',
                accessor: 'id',
              },
              {
                Header: 'Name',
                accessor: 'aboutDetails.forename',
              },
              {
                Header: 'Type',
                accessor: 'quoteType',
              },
              {
                Header: 'Created At ',
                accessor: 'createdDate',
              },
            ],
          },
        ],
        []
      )
    //   const data = useMemo(() => fireQuotes, [])
    //Tables
    return (
        <>
            <div className="lg_s content">
                {loading === true ? (
                    <div className="loading">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ): null}
                <div className="container">
                    <Table columns={columns} data={fireQuotes} />
                </div>
            </div>
        </>
    )
}

export default CasesQuoted;