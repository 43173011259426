import Sidebar from "../sections/sidebar";
import Calculator from "../calculator/Calculator";

const Quotation = () => {
    return (
        <div class="lg_s content">
            <div class="container">
                <Calculator />
            </div>
        </div>
    )
}

export default Quotation;