import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css'
import { Route, Routes } from 'react-router';
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import { useNavigate } from 'react-router-dom'
import { Fragment, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import Header from './components/sections/header';
import Quotation from './components/pages/quotationTool';
import CasesQuoted from './components/pages/casesQuoted';
import Sidebar from './components/sections/sidebar';
import Case from './components/pages/case';
import Users from './components/pages/users';
import Prices from './components/pages/prices';

function App() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  
  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            setLoggedIn(true);
        } else {
            navigate("/login")
            setLoggedIn(false);
        }
      });
     
  }, [])

  return (
    <div className="App">
      {loggedIn === true ? (
        <Fragment>
          <Header />
          <div className="d-flex justify-content-between content_section">
            <Sidebar />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/quotation" element={<Quotation />} />
              <Route path="/casesquoted" element={<CasesQuoted />} />
              <Route path="/case/:id" element={<Case />} />
              <Route path="/users" element={<Users />} />
              <Route path="/prices" element={<Prices />} />
            </Routes>
          </div>
        </Fragment>
      ) :null}
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
    </div>
  );
}

export default App;